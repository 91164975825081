import _KNOWLEDGE_MAP from '../static/knowledge-map.json';
// HACK: we keep this here even though GraphJson is defined latr in the file
// because when we need to do a migration on this file, it should happen
// up here
export let KNOWLEDGE_MAP = _KNOWLEDGE_MAP;
export var ProgressStatus;
(function (ProgressStatus) {
    ProgressStatus["NOT_ATTEMPTED"] = "not_attempted";
    ProgressStatus["ATTEMPTED"] = "attempted";
    ProgressStatus["PASSED"] = "passed";
})(ProgressStatus || (ProgressStatus = {}));
export var ProgressVideoStatus;
(function (ProgressVideoStatus) {
    ProgressVideoStatus["NOT_WATCHED"] = "not_watched";
    ProgressVideoStatus["WATCHED"] = "watched";
})(ProgressVideoStatus || (ProgressVideoStatus = {}));
