import React from 'react';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useStudentContext } from './StudentContext';
import { typedFetch } from './typedFetch';
export let NavBar = (props) => {
    let student = useStudentContext();
    let navLinks;
    let saveWarning;
    let [showUserMenu, setShowUserMenu] = React.useState(false);
    let userMenuRef = React.useRef(null);
    let handleToggleUserMenu = React.useCallback(() => {
        setShowUserMenu((old) => !old);
    }, []);
    let handleLogout = React.useCallback(async () => {
        await typedFetch({
            endpoint: '/api/auth/logout',
            method: 'post',
        });
        window.location.href = '/';
    }, []);
    let [showSaveWarning, setShowSaveWarning] = React.useState(true);
    let handleCloseWarning = React.useCallback((e) => {
        setShowSaveWarning(false);
    }, []);
    if (student.dto) {
        navLinks = (React.createElement(Box, { sx: { flexGrow: 0 } },
            React.createElement(Tooltip, { title: "Open settings" },
                React.createElement(Button, { ref: userMenuRef, onClick: handleToggleUserMenu, sx: { p: 0, color: '#111111' }, endIcon: React.createElement(ExpandMoreIcon, null) }, student.dto.email)),
            React.createElement(Menu, { sx: { mt: '30px' }, id: "menu-appbar", anchorEl: userMenuRef.current, anchorOrigin: { vertical: 'top', horizontal: 'right' }, keepMounted: true, transformOrigin: { vertical: 'top', horizontal: 'right' }, open: showUserMenu, onClose: handleToggleUserMenu },
                React.createElement(MenuItem, { onClick: handleLogout },
                    React.createElement(Typography, { textAlign: "center" }, "Logout")))));
        saveWarning = null;
    }
    else {
        navLinks = (React.createElement(React.Fragment, null,
            React.createElement(Button, { href: "/login" }, "Login"),
            React.createElement(Button, { href: "/register" }, "Register")));
        if (showSaveWarning) {
            saveWarning = (React.createElement(AppBar, { position: "static", color: "default", style: { flex: '0 0 auto' } },
                React.createElement(Alert, { severity: "warning", onClose: handleCloseWarning },
                    "Your progress is saved locally in this browser.",
                    ' ',
                    React.createElement(Link, { to: "/login" }, "Login"),
                    " to sync to other devices.")));
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(AppBar, { position: "static", color: "default", style: { flex: '0 0 auto' } },
            React.createElement(Toolbar, null,
                React.createElement(Typography, { variant: "h6", component: "div", sx: { flexGrow: 1 } },
                    React.createElement("img", { src: "/static/images/logodark.svg", style: { height: '30px' } })),
                navLinks)),
        saveWarning));
};
