export let typedLocalStorage = {
    setJson: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },
    getJson: (key) => {
        let value = localStorage.getItem(key);
        if (value === null) {
            return value;
        }
        return JSON.parse(value);
    },
    removeJson: (key) => {
        localStorage.removeItem(key);
    },
};
