import React from 'react';
import availableModules from './autogen/available-modules.json';
export let ModuleContext = React.createContext({
    playAudio: () => { throw new Error('not implemented'); },
    playTTS: () => { throw new Error('not implemented'); },
});
let channels = [null, null];
let buildModuleContext = (moduleName) => {
    let ret = {
        playAudio: (path, { channel = 0 } = {}) => {
            if (channel >= channels.length) {
                throw new Error('invalid channel ' + channel);
            }
            if (channels[channel]) {
                channels[channel].hackyPause();
            }
            let audio = new Audio(path);
            let playingPromise = audio.play();
            audio.hackyPause = async () => {
                await playingPromise;
                audio.pause();
            };
            channels[channel] = audio;
            return new Promise(resolve => {
                let doResolve = () => {
                    clearInterval(interval);
                    resolve();
                };
                let interval = setInterval(() => {
                    if (audio.paused || audio.ended) {
                        doResolve();
                    }
                }, 100);
                audio.addEventListener('paused', () => {
                    doResolve();
                }, { once: true });
                audio.addEventListener('ended', () => {
                    doResolve();
                }, { once: true });
            });
        },
        playTTS: (msg, options) => {
            return ret.playAudio('/api/tts?text=' + encodeURIComponent(msg), options);
        },
    };
    return ret;
};
export let moduleComponents = {};
availableModules.forEach(moduleName => {
    let Lesson = React.lazy(() => import(
    /* webpackChunkName: 'modules/[request]' */
    /* webpackInclude: /index\.tsx$/ */
    /* webpackExclude: /\/common\// */
    './modules/' + moduleName));
    moduleComponents[moduleName] = (React.createElement(ModuleContext.Provider, { value: buildModuleContext(moduleName) },
        React.createElement(Lesson, null)));
});
