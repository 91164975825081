import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { moduleComponents } from './ModuleContext';
import { KnowledgeMap } from './KnowledgeMap';
import { List } from './List';
import { Login } from './Login';
import { Register } from './Register';
import { Student, StudentContext } from './StudentContext';
import { typedFetch } from './typedFetch';
;
const defaultTheme = createTheme();
let App = (props) => {
    let moduleRoutes = Object.keys(moduleComponents).map(x => {
        return React.createElement(Route, { key: x, path: x, element: moduleComponents[x] });
    });
    return (React.createElement(StudentContext.Provider, { value: props.student },
        React.createElement(CssBaseline, null),
        React.createElement(ThemeProvider, { theme: defaultTheme },
            React.createElement(Router, null,
                React.createElement(React.Suspense, { fallback: "loading..." },
                    React.createElement(Routes, null,
                        React.createElement(Route, { path: "/" },
                            React.createElement(Route, { index: true, element: React.createElement(List, null) }),
                            React.createElement(Route, { path: "map", element: React.createElement(KnowledgeMap, null) }),
                            React.createElement(Route, { path: "login", element: React.createElement(Login, null) }),
                            React.createElement(Route, { path: "register", element: React.createElement(Register, null) }),
                            React.createElement(Route, { path: "modules" },
                                moduleRoutes,
                                React.createElement(Route, { path: "*", element: React.createElement("div", null, "module not found") })),
                            React.createElement(Route, { path: "*", element: React.createElement("div", null, "page not found") }))))))));
};
(async () => {
    let resp = await typedFetch({
        endpoint: '/api/student',
        method: 'get',
    });
    let student = new Student(resp.student);
    let root = createRoot(document.getElementById('content'));
    root.render(React.createElement(App, { student: student }));
})();
