import React from 'react';
import { Link } from 'react-router-dom';
export let TOOLBAR_WIDTH = '550px';
let mapToMdLinks = (videoInfos) => {
    return videoInfos.map(({ id, title, url }) => {
        return `${id}: [${title}](${url})`; // markdown format
    }).join('\n');
};
let mapFromMdLinks = (str) => {
    let ret = [];
    let lines = str.split('\n').map(x => x.trim()).filter(x => !!x);
    for (let line of lines) {
        let match = line.match(/^([a-zA-Z0-9_]+):\s*\[([^\[\]]+)\]\(([^\(\)]+)\)$/);
        if (!match) {
            throw new Error('md links not valid');
        }
        ret.push({
            id: match[1],
            title: match[2],
            url: match[3],
        });
    }
    return ret;
};
let ToolbarForOne = (props) => {
    let ref = React.useRef(null);
    let kmid = props.grid[props.selectedCell.i][props.selectedCell.j];
    let node = props.knowledgeGraph.getNodeData(kmid);
    let [tempId, setTempId] = React.useState(kmid);
    let [tempTitle, setTempTitle] = React.useState(node.title);
    let [tempDesc, setTempDesc] = React.useState(node.description);
    let [tempStudentVids, setTempStudentVids] = React.useState('');
    let [tempTeacherVids, setTempTeacherVids] = React.useState('');
    let [tempForTeachers, setTempForTeachers] = React.useState(Boolean(node.forTeachers));
    React.useEffect(() => {
        setTempTitle(node.title);
        setTempDesc(node.description);
        setTempStudentVids(mapToMdLinks(node.studentVideos));
        setTempTeacherVids(mapToMdLinks(node.teacherVideos));
        setTempForTeachers(Boolean(node.forTeachers));
    }, [node]);
    React.useEffect(() => {
        setTempId(kmid);
        setTimeout(() => {
            ref.current.focus();
            ref.current.select();
        }, 0);
    }, [kmid]);
    let handleChangeId = React.useCallback((e) => {
        setTempId(e.target.value);
    }, []);
    let handleChangeTitle = React.useCallback((e) => {
        setTempTitle(e.target.value);
    }, []);
    let handleChangeDesc = React.useCallback((e) => {
        setTempDesc(e.target.value);
    }, []);
    let handleChangeStudentVids = React.useCallback((e) => {
        setTempStudentVids(e.target.value);
    }, []);
    let handleChangeTeacherVids = React.useCallback((e) => {
        setTempTeacherVids(e.target.value);
    }, []);
    let handleChangeForTeachers = React.useCallback((e) => {
        setTempForTeachers(e.target.checked);
    }, []);
    let handleSubmit = React.useCallback((e) => {
        e.preventDefault();
        let newStudentVids;
        try {
            newStudentVids = mapFromMdLinks(tempStudentVids);
        }
        catch (e) {
            if (!(e instanceof Error && e.message.includes('md links not valid'))) {
                throw e;
            }
            alert('Student video links were not valid');
            return;
        }
        let newTeacherVids;
        try {
            newTeacherVids = mapFromMdLinks(tempTeacherVids);
        }
        catch (e) {
            if (!(e instanceof Error && e.message.includes('md links not valid'))) {
                throw e;
            }
            alert('Teacher video links were not valid');
            return;
        }
        props.onChangeNode(kmid, {
            id: tempId,
            title: tempTitle,
            description: tempDesc,
            studentVideos: newStudentVids,
            teacherVideos: newTeacherVids,
            forTeachers: tempForTeachers,
        });
    }, [
        kmid,
        tempId,
        tempTitle,
        tempDesc,
        tempStudentVids,
        tempTeacherVids,
        tempForTeachers,
        props.onChangeNode,
    ]);
    let handleMoveTreeLeft = React.useCallback((e) => {
        props.onMoveTreeLeft(kmid);
    }, [kmid, props.onMoveTreeLeft]);
    let handleMoveTreeRight = React.useCallback((e) => {
        props.onMoveTreeRight(kmid);
    }, [kmid, props.onMoveTreeRight]);
    let handleMoveTreeUp = React.useCallback((e) => {
        props.onMoveTreeUp(kmid);
    }, [kmid, props.onMoveTreeUp]);
    let handleMoveTreeDown = React.useCallback((e) => {
        props.onMoveTreeDown(kmid);
    }, [kmid, props.onMoveTreeDown]);
    return (React.createElement("div", null,
        React.createElement("form", { onSubmit: handleSubmit },
            React.createElement("div", null,
                React.createElement("input", { type: "text", style: { width: '100%' }, ref: ref, value: tempId, placeholder: "id", onChange: handleChangeId })),
            React.createElement("div", null,
                React.createElement("input", { type: "text", style: { width: '100%' }, value: tempTitle, placeholder: "title", onChange: handleChangeTitle })),
            React.createElement("div", null,
                React.createElement("textarea", { style: { width: '100%' }, value: tempDesc, placeholder: "description", onChange: handleChangeDesc })),
            "Learning videos:",
            React.createElement("div", null,
                React.createElement("textarea", { style: { width: '100%' }, value: tempStudentVids, placeholder: "videos for learning", onChange: handleChangeStudentVids })),
            "Teaching videos:",
            React.createElement("div", null,
                React.createElement("textarea", { style: { width: '100%' }, value: tempTeacherVids, placeholder: "videos for teaching", onChange: handleChangeTeacherVids })),
            React.createElement("div", null,
                React.createElement("label", null,
                    "This module is only for teachers/parents",
                    React.createElement("input", { type: "checkbox", onChange: handleChangeForTeachers, checked: tempForTeachers }))),
            React.createElement("div", null,
                React.createElement("button", { type: "submit" }, "Apply"))),
        React.createElement("div", null,
            React.createElement(Link, { to: `/modules/${kmid}` }, "Go to lesson")),
        React.createElement("div", null, JSON.stringify(props.selectedCell)),
        React.createElement("div", null,
            React.createElement("button", { onClick: handleMoveTreeLeft }, "Move Tree Left")),
        React.createElement("div", null,
            React.createElement("button", { onClick: handleMoveTreeRight }, "Move Tree Right")),
        React.createElement("div", null,
            React.createElement("button", { onClick: handleMoveTreeUp }, "Move Tree Up")),
        React.createElement("div", null,
            React.createElement("button", { onClick: handleMoveTreeDown }, "Move Tree Down"))));
};
export let AdminToolbar = (props) => {
    let forOne = null;
    if (props.selectedCells.length === 1) {
        forOne = (React.createElement(ToolbarForOne, { selectedCell: props.selectedCells[0], knowledgeGraph: props.knowledgeGraph, grid: props.grid, onChangeNode: props.onChangeNode, onMoveTreeLeft: props.onMoveTreeLeft, onMoveTreeRight: props.onMoveTreeRight, onMoveTreeUp: props.onMoveTreeUp, onMoveTreeDown: props.onMoveTreeDown }));
    }
    let handleDelete = React.useCallback((e) => {
        props.onDeleteIds(props.selectedCells.map(x => props.grid[x.i][x.j]));
    }, [props.selectedCells, props.grid, props.onDeleteIds]);
    let forSelected = null;
    if (props.selectedCells.length > 0) {
        forSelected = (React.createElement("div", null,
            React.createElement("button", { onClick: handleDelete }, "Delete Selected")));
    }
    let handleSelectAll = React.useCallback((e) => {
        props.onSelectIds(props.knowledgeMap.nodes.map(x => x.id));
    }, [props.onSelectIds]);
    let downloadJson = React.useCallback((data, filename) => {
        let blob = new Blob([JSON.stringify(data, undefined, 2)], { type: 'text/json' });
        let a = document.createElement('a');
        //a.style = 'display: none';
        document.body.appendChild(a);
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(a.href);
        a.remove();
    }, []);
    let handleExportGraph = React.useCallback((e) => {
        downloadJson(props.knowledgeMap, 'knowledge-map.json');
    }, [props.knowledgeMap, downloadJson]);
    let toolbarStyle = {
        position: 'fixed',
        width: TOOLBAR_WIDTH,
        height: '100%',
        background: 'red',
        top: 0,
        right: 0,
    };
    return (React.createElement("div", { style: toolbarStyle },
        forOne,
        forSelected,
        React.createElement("div", null,
            React.createElement("button", { onClick: handleSelectAll }, "Select All")),
        React.createElement("div", null,
            React.createElement("button", { onClick: handleExportGraph }, "Export Graph")),
        React.createElement("div", null, props.knowledgeMap.nodes.length),
        React.createElement("div", null,
            props.rows + 1,
            "X",
            props.cols + 1)));
};
