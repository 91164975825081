import React from 'react';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import { typedFetch } from './typedFetch';
;
export let Register = (props) => {
    let [loading, setLoading] = React.useState(false);
    let [fields, setFields] = React.useState({
        email: { error: false, helperText: '' },
    });
    const handleSubmit = React.useCallback(async (event) => {
        event.preventDefault();
        if (loading) {
            return;
        }
        setLoading(true);
        setFields({
            email: { error: false, helperText: '' },
        });
        const data = new FormData(event.currentTarget);
        try {
            let resp = await typedFetch({
                endpoint: '/api/auth/register',
                method: 'post',
                body: {
                    name: data.get('name'),
                    email: data.get('email'),
                    password: data.get('password'),
                },
            });
            if ('success' in resp) {
                window.location.href = '/';
                return;
            }
            if (resp.errorCode === 'auth.register.email.invalid' ||
                resp.errorCode === 'auth.register.email.alreadyRegistered') {
                let narrow = resp; // to narrow the type for the callback
                setFields(fields => ({
                    ...fields,
                    email: { error: true, helperText: narrow.message }
                }));
            }
            else {
                let check = resp;
            }
        }
        finally {
            setLoading(false);
        }
    }, [loading]);
    return (React.createElement(Container, { component: "main", maxWidth: "xs" },
        React.createElement(Box, { sx: {
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            } },
            React.createElement(Avatar, { sx: { m: 1, bgcolor: 'secondary.main' } },
                React.createElement(LockOutlinedIcon, null)),
            React.createElement(Typography, { component: "h1", variant: "h5" }, "Register"),
            React.createElement(Box, { component: "form", onSubmit: handleSubmit, noValidate: true, sx: { mt: 1 } },
                React.createElement(TextField, { margin: "normal", required: true, fullWidth: true, id: "name", label: "Name", name: "name", autoComplete: "name", autoFocus: true }),
                React.createElement(TextField, { margin: "normal", required: true, fullWidth: true, id: "email", label: "Email", name: "email", autoComplete: "email", error: fields.email.error, helperText: fields.email.helperText }),
                React.createElement(TextField, { margin: "normal", required: true, fullWidth: true, name: "password", label: "Password", type: "password", id: "password", autoComplete: "new-password" }),
                React.createElement(LoadingButton, { type: "submit", fullWidth: true, variant: "contained", loading: loading, loadingIndicator: "Registering...", sx: { mt: 3, mb: 2 } }, "Register")))));
};
