export let typedFetch = async (request) => {
    // HACK: it seems like type narrowing does not work with conditional types
    // in typescript. It's not clear to me if this function is actually being
    // typechecked or not. And it's not possible to call other functions
    // using the same conditional types as above.
    let url = request.endpoint;
    if ('params' in request && request.params) {
        let p = request.params;
        for (let k in request.params) {
            // TODO: not sure why I need to force "as string' here, but it doesn't
            // work without it.
            url = url.replace(':' + k, p[k]);
        }
    }
    if (request.query) {
        url += '?' + new URLSearchParams(request.query).toString();
    }
    let response = await fetch(url, {
        method: request.method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: request.method === 'post' ? JSON.stringify(request.body) : undefined,
    });
    return response.json();
};
